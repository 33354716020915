.inputSearch{
    border: none;
    outline: none;    
}
.results-list {
    position: absolute;
    left: 0;
    top: 25;
    z-index: 999;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 0px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
    text-align: left;    
  }  
  .search-result {
    padding: 10px 5px;
  }  
  .search-result:hover {
    background-color: #efefef;
    cursor: pointer;
  }
  .results-list-loading {
    position: absolute;
    left: 0;
    top: 25;
    z-index: 999;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 0px;
    margin-top: 1rem;
    max-height: 300px;
    text-align: left;    
    padding: 12px;
  }

  .tcc-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }