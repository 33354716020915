:root {
  --blue: #007e9f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}

::-webkit-scrollbar:vertical {
  width: 0.5rem;
  margin: 0 -0.25rem;
}

::-webkit-scrollbar-thumb {
  border: 0.125rem solid white;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.3);
}

.tcc-color-blue {
  color: var(--blue) !important;
}

.tcc-color-gray {
  color: #898f91 !important;
}

.tcc-bold {
  font-weight: 600 !important;
}

.tcc-nowrap {
  white-space: nowrap !important;
}

.tcc-text-uppercase {
  text-transform: uppercase !important;
}

.tcc-ml2 {
  margin-left: 1rem !important;
}

.tcc-ptb-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.tcc-plr-0 { 
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.tcc-p0 {
  padding: 0 !important;
}

.tcc-box-title {
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  color: #428302;
}

.tcc-link {
  color: var(--blue);
  text-decoration: none;
}

.tcc-pointer {
  cursor: pointer;
}